import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Container, Row, Col, Button, Form, FloatingLabel
} from 'react-bootstrap';
import $ from 'jquery';

export default function Policies(props) {
    React.useEffect(function() {
        setTimeout(() => {
            $("[data-target='" + window.location.hash + "']").click();  
        }, 500);

        $(document).on("click", ".list-group-item-action", function() {
            $($(this).data("target")).slideToggle();
            $(this).toggleClass("active");
        });
    }, []);

    return (<>
        <Container fluid id="policy-page" className="py-5">
            <Row className="justify-content-center my-5">
                <Col xs={11} xl={9} xxl={7} className="rounded-3 border shadow-dark bg-white text-black">
                    <div className="row my-5">
                        <div className="col">
                            <h1>Policies</h1>
                        </div>
                    </div>
                    <div className="row justify-content-center mb-5">
                        <div className="col">
                            <ul className="list-group">
                                {/* {{!-- Header --}} */}
                                <li className="list-group-item list-group-item-dark">
                                    <div className="row">
                                        <div className="col-8">
                                            Policy
                                        </div>
                                        <div className="col-4">
                                            Date Updated
                                        </div>
                                    </div>
                                </li>
                                {/* {{!-- TICKET DISCLAIMER --}} */}
                                <li className="list-group-item list-group-item-action" data-target="#policy4">
                                    <div className="row">
                                        <div className="col-8">
                                            Ticket Disclaimer
                                        </div>
                                        <div className="col-4">
                                            October 2021
                                        </div>
                                    </div>
                                </li>
                                <li id="policy4" className="list-group-item collapse">
                                    <div className="row">
                                        <div className="col">
                                            <p>Read this warning before entering any haunted attraction, the Brimstone Haunted Hayride, The Bog, Psychosis, Zombie Assault, Dead Shot Paintball, Cranium Crusher and Axe Throwing. Brimstone Haunt, LLC reserves the right to refuse admission to anyone. You will experience intense audio, lighting, extreme low visibility, strobe lights, fog, damp or wet conditions, moving floors, special effects, sudden actions, and an overall physically demanding environment. DO NOT ENTER the attraction if you are intoxicated, taking medication or using drugs or wearing any form of cast, medical brace, crutches, or have any type of physical limitations. You will not be admitted if these conditions are noticed by our staff. DO NOT ENTER IF YOU SUFFER FROM ASTHMA, HEART CONDITIONS, SEIZURES, MENTAL, PHYSICAL, RESPIRATORY, OR MEDICAL PROBLEMS OR IF YOU ARE PREGNANT OR SUFFER FROM ANY MENTAL DISEASES INCLUDING CLAUSTROPHOBIA. DO NOT smoke, run, eat or drink during the attraction. NO touching actors, customers, or props inside the attraction. NO video or flash photography may be taken during the attraction. Failing to follow these rules will result in being removed from the property immediately. NO REFUNDS. ENTER AT YOUR OWN RISK! Your ticket is a revocable license and may be taken and admission refused upon refund of purchase price. Holder of the ticket understands that there are inherent risks involved with attending these attractions. Holder voluntarily assumes all risks and dangers associated with participation in these attractions. In consideration and acceptance of entrance into either attraction, the holder agrees to release the operator, Brimstone Haunt, LLC, all affiliates, owners, and employees from any liability, harm, injury or death, cost or expenses whatsoever that may arise directly or indirectly from attending these attractions at this location.</p>
                                        </div>
                                    </div>
                                </li>
                                {/* {{!-- PHOTO AND VIDEO --}} */}
                                <li className="list-group-item list-group-item-action" data-target="#policy2">
                                    <div className="row">
                                        <div className="col-8">
                                            Photography and Video
                                        </div>
                                        <div className="col-4">
                                            August 2018
                                        </div>
                                    </div>
                                </li>
                                <li id="policy2" className="list-group-item collapse">
                                    <div className="row">
                                        <div className="col">
                                            <h5>Vistor Camera Use</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>All photography and video is prohibited inside of the attractions. It is allowed in queue lines and the midway/tickets/concessions area.</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h5>Our Camera Use</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>While visiting Brimstone Haunt, you may be photographed or video recorded. These images and video may be used in the future for educational or marketing purposes. Admission to Brimstone Haunt or its programs serves as permission for image and video use.</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h5>Contact Us</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>If you have questions or comments about this Photography and Video Policy, please contact us at: <a href="mailto:contact@brimstonehaunt.com">contact@BrimstoneHaunt.com</a>.</p>
                                        </div>
                                    </div>
                                </li>
                                {/* {{!-- COOKIES --}} */}
                                <li className="list-group-item list-group-item-action" data-target="#policy3">
                                    <div className="row">
                                        <div className="col-8">
                                            Web Cookies
                                        </div>
                                        <div className="col-4">
                                            September 2020
                                        </div>
                                    </div>
                                </li>
                                <li id="policy3" className="list-group-item collapse">
                                    <div className="row">
                                        <div className="col">
                                            <h5>Introduction</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>Brimstone Haunt may use cookies, and other tracking technologies when you visit our website [brimstonehaunt.com.com], including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the “Site”) to help customize the Site and improve your experience.</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>We reserve the right to make changes to this Cookie Policy at any time and for any reason. We will alert you about any changes by updating the “Last Updated” date of this Cookie Policy. Any changes or modifications will be effective immediately upon posting the updated Cookie Policy on the Site, and you waive the right to receive specific notice of each such change or modification.</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>You are encouraged to periodically review this Cookie Policy to stay informed of updates. You will be deemed to have been made aware of, will be subject to, and will be deemed to have accepted the changes in any revised Cookie Policy by your continued use of the Site after the date such revised Cookie Policy is posted.</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h5>Use of Cookies</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>A “cookie” is a string of information which assigns you a unique identifier that we store on your computer. Your browser then provides that unique identifier to use each time you submit a query to the Site. We use cookies on the Site to, among other things, keep track of services you have used, record registration information, record your user preferences, keep you logged into the Site, facilitate purchase procedures, and track the pages you visit. Cookies help us understand how the Site is being used and improve your user experience.</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <h5>Contact Us</h5>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col">
                                            <p>If you have questions or comments about this Cookie Policy, please contact us at: <a href="mailto:contact@brimstonehaunt.com">contact@BrimstoneHaunt.com</a>.</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </>)
}